import { Injectable } from "@angular/core";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";

@Injectable({
  providedIn: "root",
})
export class MusicPieceFilterService {
  private filterLogic(
    filter: string,
    includeLinkedMusicPieces: boolean,
    mp: MusicPiece,
    linkedMusicPiece?: LinkedMusicPiece,
  ): boolean {
    if (!includeLinkedMusicPieces && linkedMusicPiece) {
      return false;
    }

    const textRepresentation =
      "" +
      (mp.artist || "").toLowerCase() +
      (mp.collection || "").toLowerCase() +
      (mp.description || "").toLowerCase() +
      (mp.instrument || "").toLowerCase() +
      mp.nameOfPiece.toLowerCase();

    return textRepresentation.includes(filter.toLocaleLowerCase() || "");
  }

  private compareByLastTimeViewed(
    a: MusicPieceToDisplay,
    b: MusicPieceToDisplay,
  ): number {
    const dateAToCompare =
      a.isLinkedMusicPiece && a.linkedMusicPiece
        ? a.linkedMusicPiece.lastTimeViewed
        : a.musicPiece.lastTimeViewed;

    const dateBToCompare =
      b.isLinkedMusicPiece && b.linkedMusicPiece
        ? b.linkedMusicPiece.lastTimeViewed
        : b.musicPiece.lastTimeViewed;

    return (dateBToCompare?.valueOf() ?? 0) - (dateAToCompare?.valueOf() ?? 0);
  }

  filterByMusicPieceToDisplay<T>(
    filter: string,
    items: T[],
    selector: (t: T) => MusicPieceToDisplay,
  ): T[] {
    return items.filter((item) => {
      const musicPiece = selector(item);
      return this.filterLogic(
        filter,
        true,
        musicPiece.musicPiece,
        musicPiece.linkedMusicPiece,
      );
    });
  }

  filter(
    filter: string,
    musicPieces: MusicPieceToDisplay[],
    includeLinkedMusicPieces: boolean,
  ): MusicPieceToDisplay[] {
    return musicPieces.filter((mp) =>
      this.filterLogic(
        filter,
        includeLinkedMusicPieces,
        mp.musicPiece,
        mp.linkedMusicPiece,
      ),
    );
  }

  filterMusicPieces(filter: string, musicPieces: MusicPiece[]): MusicPiece[] {
    return musicPieces.filter((mp) =>
      this.filterLogic(filter, true, mp, undefined),
    );
  }

  sortByLastTimeViewed(
    musicPieces: MusicPieceToDisplay[],
  ): MusicPieceToDisplay[] {
    const copiedPieces = [...musicPieces];
    copiedPieces.sort((a, b) => this.compareByLastTimeViewed(a, b));
    return copiedPieces;
  }

  sortByLastTimeViewedByMusicPiece<T>(
    items: T[],
    selector: (t: T) => MusicPieceToDisplay,
  ): T[] {
    const copiedPieces = [...items];

    return copiedPieces.sort((a, b) =>
      this.compareByLastTimeViewed(selector(a), selector(b)),
    );
  }
}
